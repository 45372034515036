import * as Yup from "yup";
import { FormGroup } from "../../../components/form";
//set unique uuidv4 for fileUpload name
const uuid = crypto.randomUUID();

export const externalApplicationRequestForm: FormGroup = {
  type: "formGroup",
  fields: [
    {
      type: "formGroup",
      key: "application",
      fields: [
        {
          type: "formGroup",
          label: "KIS Program Application",
          key: "applicationBase",
          fields: [
            {
              type: "field",
              key: "advisory",
              fieldType: "advisory",
              props: {
                type: "warning",
              },
              label:
                "Use this form to submit a KIS program application for a single child. You must be authorised on behalf of the kindergarten to submit this request. When we receive your application, you will receive an email confirming its receipt. To proceed with your application, please provide the following information.",
            },
            {
              type: "formGroup",
              key: "applicationType",
              label: "Type of Application",
              fields: [
                {
                  type: "field",
                  key: "typeOfApplication",
                  fieldType: "dropdown",
                  label: "Type of Application",
                  props: {
                    placeholder: "Select an application type",
                    options: [
                      {
                        label: "Disability only",
                        value: 1,
                      },
                      {
                        label: "Disability with Complex Medical Needs",
                        value: 2,
                      },
                      {
                        label: "Complex Medical Needs only",
                        value: 3,
                      },
                    ],
                  },
                  validation: Yup.string().required("A response is required"), // Basic required validation
                },
              ],
            },
            {
              type: "formGroup",
              key: "child",
              label: "Child Details",
              fields: [
                {
                  type: "field",
                  key: "firstName",
                  fieldType: "input",
                  label: "First Name",
                  props: {},
                  validation: Yup.string().required("A response is required"), // Basic required validation
                },
                {
                  type: "field",
                  key: "lastName",
                  fieldType: "input",
                  label: "Last Name",
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "fieldadv",
                  },
                  label: "Please ensure that this field is only left blank if the child does not have a surname",
                },
                {
                  type: "field",
                  key: "dateOfBirth",
                  fieldType: "calendar",
                  label: "Date of Birth",
                  validation: Yup.date().required("A response is required").max(new Date(), "Date may not be a future date"),
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "warning",
                  },
                  label:
                    "If the child is over six years old, please ensure that if a school exemption is required that it has been submitted to the DE regional office. This application cannot be assessed until this has occurred",
                },
                {
                  type: "field",
                  key: "gender",
                  fieldType: "dropdown",
                  label: "Gender",
                  props: {
                    placeholder: "Select a Gender",
                    options: [
                      {
                        label: "Female",
                        value: 1,
                      },
                      {
                        label: "Male",
                        value: 2,
                      },
                      {
                        label: "Other",
                        value: 3,
                      },
                    ],
                  },
                  validation: Yup.string().required("A response is required"),
                },
              ],
            },
            {
              type: "formGroup",
              key: "kindergarten",
              label: "Kindergarten Details",
              fields: [
                {
                  type: "field",
                  key: "fundedKinder",
                  fieldType: "search",
                  label: "Kindergarten Name",
                  props: {
                    apiPath: "FundedKinder/search",
                    placeholder: "Kindergarten Name",
                    labelValue: "accountName",
                    noResultsMessage: 'No entries found. Select "Other" to enter the name of your kindergarten',
                  },
                  validation: Yup.object().required("A response is required."),
                },
                {
                  type: "field",
                  fieldType: "input",
                  key: "kindergartenNameOther",
                  label: "Enter the Name of the Kindergarten",
                  validation: Yup.string().required("A response is required"),
                  hideExpression: (context, values, model) => {
                    return model?.fundedKinder?.id !== -1;
                  },
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "fieldadv",
                  },
                  label:
                    "Enter the first few letters of your kindergarten name to search. If you cannot find the name of the kindergarten in the list, select 'Other' and the \"Enter the Name of the Kindergarten\" field will display. Enter the name of the kindergarten in that field.",
                },
                {
                  type: "field",
                  key: "contactName",
                  fieldType: "input",
                  label: "Contact Name",
                  validation: Yup.string()
                    .required("A response is required")
                    .matches(RegExp("^[a-zA-Z- ']*$"), { message: "Please enter a valid contact name" }),
                },
                {
                  type: "field",
                  key: "contactEmail",
                  fieldType: "input",
                  label: "Contact Email",
                  validation: Yup.string()
                    .required("A response is required")
                    .matches(RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$"), { message: "Please enter a valid email address" }),
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "fieldadv",
                  },
                  label: "This will be the email used for all follow-up communication relating to the application.",
                },
                {
                  type: "field",
                  key: "contactNumber",
                  fieldType: "input",
                  label: "Contact Number",
                  validation: Yup.string()
                    .required("A response is required")
                    .matches(
                      RegExp(
                        "^((04\\d{2} ?\\d{3} ?\\d{3})|(\\+614 ?\\d{4} ?\\d{4})|((\\(02\\)|\\(03\\)|\\(08\\)) ?\\d{4} ?\\d{4})|(02|03|08)\\s?\\d{4} ?\\d{4}|(\\+613 ?\\d{4} ?\\d{4}))$"
                      ),
                      {
                        message: "Please enter a valid contact number",
                      }
                    ),
                },
              ],
            },
            {
              type: "formGroup",
              label: "Application and Evidence Upload",
              key: "applicationFiles",
              fields: [
                {
                  type: "field",
                  fieldType: "input",
                  key: "FileUuid",
                  defaultValue: uuid,
                  hideExpression: () => {
                    return true;
                  },
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "warning",
                  },
                  label: "Upload your application forms and all supporting information documents in the sections as indicated.",
                },
                {
                  type: "field",
                  key: "applicationFormPartOne",
                  fieldType: "file",
                  label: "Application Form - Section 1",
                  warn: "This section is for the KIS program application form - Section 1",
                  validation: Yup.object({
                    files: Yup.array().min(1, "Application cannot be submitted unless at least one document has been uploaded"),
                  }),
                  props: {
                    uuid: uuid,
                    externalForm: true,
                  },
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "fieldadv",
                  },
                  label:
                    "Please ensure that you have uploaded the KIS program application form - Section 1. Note that you cannot submit an application online unless at least one document has been uploaded for this part of the application.",
                },
                {
                  type: "pInput",
                  key: "applicationFormAdvisoryTextOne",
                  label: "",
                  props: {
                    css: "field-adv",
                  },
                },
                {
                  type: "field",
                  key: "applicationFormPartTwo",
                  fieldType: "file",
                  label: "Application Form - Section 2",
                  warn: "This section is for the KIS program application form - Section 2",
                  validation: Yup.object({
                    files: Yup.array().min(1, "Application cannot be submitted unless at least one document has been uploaded"),
                  }),
                  props: {
                    uuid: uuid,
                    externalForm: true,
                  },
                },

                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "fieldadv",
                  },
                  label:
                    "Please ensure that you have uploaded the KIS program application form - Section 2. Note that you cannot submit an application online unless at least one document has been uploaded for this part of the application.",
                },
                {
                  type: "field",
                  key: "evidenceDocuments",
                  fieldType: "file",
                  label: "Evidence Documents",
                  warn: "This section is for all the Evidence Documents being used to support the application",
                  validation: Yup.object({
                    files: Yup.array().min(1, "Application cannot be submitted unless at least one document has been uploaded"),
                  }),
                  props: {
                    uuid: uuid,
                    externalForm: true,
                  },
                },
                {
                  type: "field",
                  key: "advisory",
                  fieldType: "advisory",
                  props: {
                    type: "fieldadv",
                  },
                  label:
                    "Please ensure that you have uploaded all Evidence Documents before clicking Submit. Note that you cannot submit unless at least one document has been uploaded for this part of the application.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "field",
      props: {},
      key: "captcha",
      fieldType: "captcha",
      validation: Yup.string().required("Please complete the CAPTCHA Challenge"),
    },
  ],
};
