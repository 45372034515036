import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { PageHeader } from "../../components/header/PageHeader";
import AssignAssessorComponent from "../../components/applicationComponents/assignAssessor/AssignAssessorComponent";

export default function AssignAssessorPage() {
  const [id, setId] = useState("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const idParam = searchParams.get("id");
    setId(idParam ?? "");
  }, []);
  const headerText = [
    {
      class: "flex align-center mt-0",
      value: "Edit Assign Assessor",
      icon: "view-app-icon mr-3 mt-0",
    },
  ];
  return (
    <>
      <Header />
      <PageHeader headerText={headerText} icon={"icon"} />
      <AssignAssessorComponent disabled={false} id={id} />
    </>
  );
}
