import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState } from "react";
import { useEffect } from "react";
import { eventEmitter } from "../../shared/event";

export function SpinnerComponent() {
  const [loadingCount, setLoadingCount] = useState(0);
  useEffect(() => {
    eventEmitter.addListener("loading", loading);

    return () => {
      eventEmitter.removeListener("loading", loading);
    };
  }, []);

  function loading({ isLoading }: { isLoading: boolean }) {
    setLoadingCount((curr) => (isLoading ? curr + 1 : curr - 1));
  }

  return (
    <>
      {" "}
      {loadingCount > 0 && (
        <div className="spinner-bg">
          <ProgressSpinner className="pointer-events-none spinner-container" aria-label="Loading" />{" "}
        </div>
      )}
    </>
  );
}
