import { FormGroup } from "../../../components/form";
import * as Yup from "yup";

export const assignAssessorForm = (options: any[]): FormGroup => ({
  type: "formGroup",
  fields: [
    {
      type: "field",
      key: "assignedAssessor",
      label: "Application Assigned to",
      fieldType: "dropdown",
      props: {
        placeholder: "Select an Assessor",
        options: options,
      },
      validation: Yup.string().required("A response is required"),
    },
  ],
});
