import { Header } from "../../components/header/Header";
import React, { useEffect, useState } from "react";
import ExternalLink from "../../components/externalLink/externalLink";
import ChildComponent from "../../components/applicationComponents/child/ChildComponent";
import KinderComponent from "../../components/applicationComponents/kindergarten/KindergartenComponent";
import ApplicationFiles from "../../components/applicationComponents/applicationFiles/ApplicationFilesComponent";
import { PageHeader } from "../../components/header/PageHeader";
import ApplicationTypeComponent from "../../components/applicationComponents/applicationType/ApplicationTypeComponent";
import AssignAssessorComponent from "../../components/applicationComponents/assignAssessor/AssignAssessorComponent";
import { useApplicationContext } from "../../components/applicationComponents/context/applicationContext";

export default function ApplicationView() {
  const [loaded, setLoaded] = useState(false);
  const headerText = [
    {
      class: "flex align-center mt-0",
      value: "View Application",
      icon: "view-app-icon mr-3 mt-0",
    },
  ];
  const { id, applicationBase, rehydrate } = useApplicationContext();
  useEffect(() => {
    rehydrate().then(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <div className="w-full">
      <Header />
      <PageHeader headerText={headerText} icon={"icon"} />

      {loaded && (
        <div className="flex flex-column align-items-start w-full">
          <ApplicationTypeComponent disabled={true} data={applicationBase.applicationType} />
          <ChildComponent disabled={true} data={applicationBase.child} />
          <AssignAssessorComponent disabled={true} data={applicationBase.assignAssessor ?? {}} />
          <KinderComponent disabled={true} data={applicationBase.kindergarten} />
          <ExternalLink linkLabel="Application and Evidence Document Upload" applicationId={id} />
          <ApplicationFiles disabled={true} data={applicationBase.applicationFiles} />
        </div>
      )}
    </div>
  );
}
