import React, { createContext, useState, useContext, useEffect } from "react";
import { useApiClient } from "../../../shared/customHooks";
import { useParams } from "react-router-dom";

// Create a Context
const ApplicationContext = createContext<IApplicationContext>({
  id: "",
  application: {},
  applicationBase: {},
  rehydrate: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setApplication: (application: any) => {},
});

// Create a provider component
export const ApplicationContextProvider = ({ children }: any) => {
  const [application, setApplication] = useState<any>({});
  const { fetchData } = useApiClient("Application");
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    getApplication(id ?? "");
  }, [id]);

  function rehydrate(): Promise<void | never> {
    return getApplication(id ?? "");
  }

  function getApplication(id: string): Promise<void | never> {
    return fetchData(id).then((res) => {
      setApplication(res.data), setLoaded(true);
    });
  }
  return (
    <ApplicationContext.Provider
      value={{
        id: id ?? "",
        application,
        applicationBase: application.applicationBase,
        rehydrate,
        setApplication,
      }}>
      {loaded && children}
    </ApplicationContext.Provider>
  );
};

interface IApplicationContext {
  id: string;
  application: any;
  applicationBase: any;
  rehydrate: () => Promise<void | never>;
  setApplication: (application: any) => void;
}
// Custom hook for using the context
export const useApplicationContext = (): IApplicationContext => useContext(ApplicationContext);
