import React from "react";
import { Field, ErrorMessage } from "formik";
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";

interface InputTxtAreaProps extends InputTextareaProps {
  label: string;
  name: string;
  value: any;
}
export default function InputTextareaComponent(props: InputTxtAreaProps) {
  const { name, label, ...rest } = props;
  return (
    <div className="flex flex-column w-full">
      {!props?.hidden && (
        <label htmlFor={name} id={name.split(".").pop()}>
          {" "}
          {label}
        </label>
      )}
      <Field alt={name} name={name} {...rest} as={InputTextarea} />
      <ErrorMessage name={name ?? ""} render={(msg) => <div className="error">{msg}</div>} />
    </div>
  );
}
