import React from "react";

export function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="float-left w-full footer pl-0">
        <div className="flex justify-content-between w-full mt-6 fixed bottom-0 ">
          {" "}
          <div className="landing-footer p-0">
            <p className="m-0">
              The Victorian Government Department of Education. Copyright &copy; {currentYear}. All rights reserved Kindergarten Inclusion Support.
            </p>{" "}
            <div className="divider my-2"></div>
            <div className="flex align-items-center">
              {" "}
              <img src="/assets/icons/email-black.png" />
              <span className="mr-2">support@kisapplications.com.au </span> <span className="mx-2">|</span>
              <img src="/assets/icons/phone-black.png" /> <span>1300 564 149</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
