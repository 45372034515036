import React from "react";
import { rowColumn } from "../../components/table/Table";

export const applicationsListTable = (currPath: string): rowColumn[] => [
  {
    label: "KIS ID",
    column: "id",
    type: "string",
    allowSort: true,
    sortOn: "Id",
  },
  {
    label: "First Name",
    column: "applicationBase.child.firstName",
    type: "string",
    allowSort: true,
    sortOn: "ApplicationBase.Child.FirstName",
  },

  {
    label: "Last Name",
    column: "applicationBase.child.lastName",
    type: "string",
    allowSort: true,
    sortOn: "ApplicationBase.Child.LastName",
  },
  {
    label: "Kindergarten",
    column: "applicationBase.kindergarten.fundedKinderName",
    type: "string",
    allowSort: true,
    sortOn: "ApplicationBase.Kindergarten.FundedKinderName",
    body: (rowData) => {
      return (
        <p>
          {rowData.applicationBase.kindergarten.fundedKinderName === "Other"
            ? rowData.applicationBase.kindergarten.kindergartenNameOther
            : rowData.applicationBase.kindergarten.fundedKinderName}
        </p>
      );
    },
  },
  {
    label: "Date Created",
    column: "dateCreated",
    type: "date",
    allowSort: true,
    sortOn: "DateCreated",
  },
  {
    label: "Actions",

    body: (rowData) => <a href={`${currPath}/${rowData.id}`}>View Details</a>,
  },
];
