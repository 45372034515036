import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import { useApiClient } from "../../../shared/customHooks";
import { eventEmitter } from "../../../shared/event";
import { ToastInfo } from "../../toaster/toaster";
import { AutoComplete } from "primereact/autocomplete";

interface tableColumn {
  field: string;
  header: string;
}

interface propTypes {
  columns?: tableColumn[];
  dataSelectionKey?: string;
  apiPath: string;
  name: string;
  label: string;
  labelValue: string;
  disabled: boolean;
  noResultsMessage?: string;
  noResultsValue?: string;
}

const SearchSelectComponent: React.FC<propTypes> = ({
  apiPath,
  name,
  label,
  dataSelectionKey,
  labelValue,
  disabled,
  noResultsMessage,
  noResultsValue = "Other",
}) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const [searchData, setSearchData] = useState([]);
  const [active, setActive] = useState(false);
  const [query, setQuery] = useState("");
  const api = useApiClient(apiPath, { requireAuth: false });

  useEffect(() => {
    const data: any = values;
    const modeValue = name.split(".").reduce((prev, curr) => prev[curr], data);
    if (modeValue == null) return;
    setQuery(modeValue[labelValue] ?? "");
  }, [values]);

  const debouncedFetchSearchResults = useCallback(
    debounce(async (searchQuery) => {
      eventEmitter.emit("loading", { isLoading: true });
      await api
        .fetchData(searchQuery)
        .then((res) => {
          setSearchData(res.data.data);
        })
        .catch((err) => {
          const data: ToastInfo = { title: "Error", message: err.message, severity: "error" };
          eventEmitter.emit("tstr", data);
          setSearchData([]);
        })
        .finally(() => {
          eventEmitter.emit("loading", { isLoading: false });
        });
    }, 300),
    [searchData] // Add stable dependencies
  );

  const handleSearchChange = (e: any) => {
    setActive(true);
    if (e.query.length >= 3) {
      debouncedFetchSearchResults(e.query);
    }
  };

  const handleSelect = (selection: any) => {
    if (selection) {
      // Notify parent with the selected item
      const value = dataSelectionKey ? selection[dataSelectionKey] : selection;
      setFieldValue(name, value);
      setActive(false);
    }
  };
  const handleBlur = async () => {
    //blur fires on select 1/2 the time nasty fix
    //https://github.com/primefaces/primeng/issues/10808
    const suggestions = document.querySelectorAll(".p-autocomplete-panel ul li:hover");
    if (suggestions.length === 0) {
      const isValidSelection = searchData.some((item) => item[labelValue] === query);

      if (!isValidSelection) {
        await setFieldValue(name, null);
        await setFieldTouched(name, true);
        setActive(false);
      }
      return;
    }
  };

  return (
    <>
      <div className="flex flex-column w-full">
        <label htmlFor="search">{label}</label>
        {noResultsMessage != null && searchData.length == 1 && active && searchData[0][labelValue] === noResultsValue && (
          <span className="error">{noResultsMessage}</span>
        )}
        <div className="flex flex-row auto-complete kg-search">
          {" "}
          <span className="pi pi-search p-input-icon -mr-icon "></span>
          <AutoComplete
            disabled={disabled}
            placeholder="Search"
            name="search"
            field="accountName"
            className="w-full search-bar"
            value={query}
            dropdown
            suggestions={searchData}
            completeMethod={handleSearchChange}
            onSelect={(e) => handleSelect(e.value)}
            onChange={(e) => setQuery(e.value)}
            onBlur={handleBlur}
          />
        </div>
        <ErrorMessage name={name ?? ""} render={(msg) => <div className="error">{msg}</div>} />
      </div>
    </>
  );
};
export default SearchSelectComponent;
