import { useCallback, useEffect, useState } from "react";
import { apiClient } from "../../shared/customHooks";
import { debounce } from "lodash";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";

export interface rowColumn {
  label: string;
  column?: string;
  type?: "string" | "number" | "date" | "enum";
  allowSort?: boolean;
  sortOn?: string;
  body?: (rowData: any) => React.JSX.Element;
}

interface tableOptions {
  take?: number;
  sortDirection?: "asc" | "desc";
  searchColumns?: string[];
  skip?: number;
  sortColumn?: string;
}

interface tableProps {
  apiClient: apiClient;
  label?: string;
  route?: string;
  columns: rowColumn[];
  tableOptions?: tableOptions;
}

export default function Table({ apiClient, label, columns, tableOptions, route }: tableProps) {
  const [options, setOptions] = useState<tableOptions>({ take: 10, sortDirection: "desc", skip: 0, sortColumn: "DateCreated" });
  const [tableData, setTableData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [search, setSearch] = useState("");

  const refreshTable = (newOptions: tableOptions, newSearch?: string) => {
    const payload = { ...newOptions, search: newSearch ?? search };
    apiClient.postData(payload, route ?? "all").then((res) => {
      setOptions(newOptions);
      setTableData(res.data.data);
      setTotalResults(res.data.totalResults);
    });
  };

  useEffect(() => {
    const searchColumns = columns.filter((c) => c.sortOn != null).map((c) => c.sortOn);
    const initOptions = { ...options, ...tableOptions };
    initOptions.searchColumns = searchColumns as string[];
    refreshTable(initOptions);
  }, []);

  const debouncedFetchSearchResults = useCallback(
    debounce(async (search: string) => {
      const o = { ...options };
      o.skip = 0;
      refreshTable(o, search);
    }, 300),
    [options] // Add stable dependencies
  );
  const handleSearchChange = (e: any) => {
    const search = e.target.value;
    setSearch(search);
    if (search.length >= 3 || search.length == 0) {
      debouncedFetchSearchResults(search);
    }
  };

  function sort(event: any) {
    const col = columns.find((x) => x.column == event.sortField);
    const o = { ...options };
    o.skip = 0;
    if (o.sortColumn != col?.sortOn) {
      o!.sortColumn = col?.sortOn;
    } else {
      o.sortDirection = o.sortDirection == "asc" ? "desc" : "asc";
    }
    refreshTable(o);
  }

  function onPage(event: any) {
    const newOptions = { ...options };
    newOptions.skip = event.first;
    newOptions.take = event.rows;
    refreshTable(newOptions);
  }

  function Body(rowData: any, col: rowColumn) {
    //show view details
    if (col.body) {
      return col.body(rowData);
    }
    //iterate over each key in the path to grab the most nested value in the path
    const getNestedValue = (obj: any, path: string) => {
      const result = path.split(".").reduce((previous, current) => previous && previous[current], obj);
      return result;
    };

    if (col.type === "date") {
      const dateValue = new Date(rowData[col.column ?? ""]);
      const formattedDate =
        ("0" + dateValue.getDate()).slice(-2) + "/" + ("0" + (dateValue.getMonth() + 1)).slice(-2) + "/" + dateValue.getFullYear();
      return formattedDate;
    }
    return getNestedValue(rowData, col.column ?? "");
  }

  return (
    <>
      <h1>{label}</h1>
      <div className="flex flex-row w-full mb-3">
        {" "}
        <IconField iconPosition="left" className="w-full">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText placeholder="Search" name="search" className="w-full" value={search} onChange={handleSearchChange} />
        </IconField>
        {/* <InputText placeholder="Search" name="search" className="w-full pl-5 mb-5" value={search} onChange={handleSearchChange} /> */}
      </div>
      <br />
      <DataTable
        paginator
        lazy
        onSort={sort}
        value={tableData}
        totalRecords={totalResults}
        footer={"Total number of entries: " + totalResults}
        rows={options.take}
        onPage={onPage}
        first={options.skip}
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="No entries found"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results">
        {columns.map((col) => (
          <Column key={col.column} field={col.column} header={col.label} sortable={col.allowSort} body={(rowData) => Body(rowData, col)} />
        ))}
      </DataTable>
    </>
  );
}
