import React from "react";
import { useApiClient } from "../../../shared/customHooks";
import BaseApplicationComponent from "../baseApplicationComponent/BaseApplicationComponent";
import { applicationTypeForm } from "../../../shared/formObjects/applicationTypeForm";

function ApplicationTypeComponent({ disabled, data }: any) {
  const api = useApiClient("ApplicationType");

  return (
    <>
      <BaseApplicationComponent
        formBody={applicationTypeForm}
        headerLabel="Type of Application"
        editPath="applicationtype"
        apiClient={api}
        disabled={disabled}
        data={data}
      />
    </>
  );
}

export default ApplicationTypeComponent;
