import React, { useState } from "react";
import FormContainer from "../../form/FormContainer";
import ComponentHeader, { ComponentHeadeProps } from "../../componentHeader/componentHeader";
import { apiClient } from "../../../shared/customHooks";
import { FormGroup } from "../../form";
import { useLocation } from "react-router-dom";
import { useApplicationContext } from "../context/applicationContext";

interface BaseApplicationProps {
  headerLabel: string;
  apiClient: apiClient;
  formBody: FormGroup;
  formContext?: any;
  formLabel?: string;
  disabled: boolean;
  data?: any;
  editPath: string;
  backPath?: string;
  cancelPath?: string;
  submitPath?: string;
  showEdit?: boolean;
  showBack?: boolean;
}

export default function BaseApplicationComponent({
  headerLabel,
  apiClient,
  formBody,
  formContext,
  disabled,
  data,
  editPath,
  backPath,
  cancelPath,
  showEdit,
  showBack,
  formLabel,
}: BaseApplicationProps) {
  const [context] = useState({});
  const { id } = useApplicationContext();
  const location = useLocation();

  const [headerData, setHeaderData] = useState<ComponentHeadeProps>({
    dateCreated: data?.dateCreated,
    userCreated: data?.userCreated,
    showEdit: showEdit ?? disabled,
    editPath: `${location.pathname}/${editPath}`,
    showBack: showBack ?? (!disabled && backPath != null),
    backPath: backPath,
  });

  function getHeaderData(data: any) {
    location;
    const headerData: ComponentHeadeProps = {
      dateCreated: data?.dateCreated,
      userCreated: data?.userCreated,
      showEdit: showEdit ?? disabled,
      editPath: `${location.pathname}/${editPath}`,
      showBack: showBack ?? !disabled,
      backPath: backPath,
    };
    setHeaderData(headerData);
  }

  return (
    <>
      <ComponentHeader {...headerData} label={headerLabel} />
      <FormContainer
        id={id}
        apiClient={apiClient}
        formBody={formBody}
        formTitle={formLabel}
        formContext={formContext ?? context}
        getInitialValues={getHeaderData}
        disabled={disabled}
        data={data}
        cancelPath={cancelPath}
        submitRoute=".."
      />
    </>
  );
}
