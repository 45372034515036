import React from "react";
import { useApiClient } from "../../../shared/customHooks";
import { childForm } from "../../../shared/formObjects/childForm";
import BaseApplicationComponent from "../baseApplicationComponent/BaseApplicationComponent";

function ChildComponent({ disabled, data }: any) {
  const api = useApiClient("Child");

  return (
    <BaseApplicationComponent formBody={childForm} headerLabel="Child Details" editPath="child" apiClient={api} disabled={disabled} data={data} />
  );
}

export default ChildComponent;
