import React from "react";
import { useApiClient } from "../../../shared/customHooks";
import { kinderForm } from "../../../shared/formObjects/kinderForm";
import BaseApplicationComponent from "../baseApplicationComponent/BaseApplicationComponent";

function KinderComponent({ disabled, data }: any) {
  const api = useApiClient("Kinder");

  return (
    <BaseApplicationComponent
      formBody={kinderForm}
      headerLabel="Kindergarten Details"
      editPath="kindergarten"
      apiClient={api}
      disabled={disabled}
      data={data}
    />
  );
}

export default KinderComponent;
