import { Configuration, LogLevel, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: import.meta.env.VITE_AUTHORITY,
  },
  authorities: {
    signUpSignIn: {
      authority: "https://ahakisb2c.b2clogin.com/ahakisb2c.onmicrosoft.com/" + import.meta.env.VITE_AUTHORITY,
    },
  },
  authorityDomain: "ahakisb2c.b2clogin.com",
};

const scope = [`https://ahakisb2c.onmicrosoft.com/${import.meta.env.VITE_APIID}/kis.manage`, "openid", "offline_access", "email"];
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_CLIENTID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: import.meta.env.VITE_APP_URL + "/",
    postLogoutRedirectUri: import.meta.env.VITE_POSTLOGOUTREDIRECTURI,
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      piiLoggingEnabled: true,
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: scope,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const silentRequest = {
  scopes: scope,
  loginHint: "example@domain.net",
};

export const protectedResources = {
  apiPD: {
    endpoint: import.meta.env.VITE_API_KEY,
    scopes: scope,
  },
};
