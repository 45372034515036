import React, { useEffect, useState } from "react";
import { useApiClient } from "../../shared/customHooks";
import { externalApplicationRequestForm } from "../../shared/formObjects/external/externalApplicationRequestForm";
import FormContainer from "../../components/form/FormContainer";
import { PageHeader } from "../../components/header/PageHeader";
import ExternalFormsHeader from "./header/ExternalFormsHeader";

function ExternalApplicationRequest() {
  const externalEvidenceApi = useApiClient("ExternalInitialApplication/PostApplication", { requireAuth: false });
  const [formContext] = useState({ requireAuth: false });
  const [success, setSuccess] = useState(false);
  const headerText = [
    {
      class: "p-0 m-0 flex align-center",
      value: "KIS Application Submission Form",
      icon: "app-icon mt-3",
    },
  ];

  useEffect(() => {}, [success]);

  const handleSUccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <ExternalFormsHeader></ExternalFormsHeader>
      <PageHeader headerText={headerText} hideBreadcrumbs={true} icon={"icon"}></PageHeader>
      {!success && (
        <FormContainer
          apiClient={externalEvidenceApi}
          formBody={externalApplicationRequestForm}
          formTitle=""
          formContext={formContext}
          saveSuccessCB={handleSUccess}
          showInternalNotes={false}
        />
      )}
      {success && (
        <div className="flex align-items-center justify-content-center access">
          <img src="/assets/icons/bulb.svg" width="80" className="border border-2 border-circle p-3 border-primary bg-white" />
          <h3 className="bg-grey p-5 adv-text-access">
            Thank you for submitting your KIS program application. You will receive an email confirming receipt of your application shortly.
          </h3>
        </div>
      )}
    </>
  );
}

export default ExternalApplicationRequest;
