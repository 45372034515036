import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface ComponentHeadeProps {
  dateCreated?: string;
  userCreated?: string;
  editPath?: string;
  showEdit?: boolean;
  backPath?: string;
  showBack?: boolean;
  label?: string;
}

export default function ComponentHeader({
  dateCreated,
  userCreated,
  editPath,
  showEdit = false,
  backPath,
  showBack = false,
  label,
}: ComponentHeadeProps) {
  const navigate = useNavigate();

  function formatDate(date: string) {
    const d = new Date(date);
    return `${d.toLocaleDateString("en-CA")} ${d.toLocaleString("en-CA", { timeStyle: "medium", hour12: false })}`;
  }

  return (
    <div className="w-full border-0 shadow-none comp-header">
      <div className="flex justify-content-between align-items-center w-full bg-transparent my-3 border-purple border-2 text-purple font-bold flex-wrap header-height">
        <div>
          <h4 className="text-purple flex align-items-center">
            <span className="pl-1 mx-1 mt-0"></span>
            <span>{label}</span>
          </h4>
        </div>
        <div>
          {userCreated != null && (
            <>
              <span className="user-icon ml-3 pr-2"></span> <span> {userCreated}</span>
              <span className="time-stamp pr-2"></span> <span>{formatDate(dateCreated!)}</span>
            </>
          )}
        </div>
        <div>
          {showEdit && editPath && (
            <Button onClick={() => navigate(editPath ?? "")} label="Edit" className="bg-transparent border-none text-purple mr-2 edit-icon" />
          )}{" "}
          {showBack && (
            <Button
              onClick={() => (backPath ? navigate(backPath) : navigate(-1))}
              label="Back"
              className="bg-transparent border-none text-purple mr-2 back-icon"
            />
          )}
        </div>
      </div>
    </div>
  );
}
