import { ErrorMessage, useFormikContext } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from "file-saver";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useApiClient } from "../../../shared/customHooks";
import { Modal, ModalRef } from "../../modal/modal";
import { AdvisoryText } from "../../AdvisoryText";
import { FormContext } from "../context/formContext";
import { useMsal } from "@azure/msal-react";
import { postValidateFile } from "../scanner/ScannerService";
import { eventEmitter } from "../../../shared/event";
import { ToastInfo } from "../../toaster/toaster";

export interface FileUploadProps {
  uuid: string;
  name: string;
  label: string;
  container?: string;
  title: string;
  value: { files: fileData[] };
  warn?: string;
  disabled: boolean;
  externalForm?: boolean;
}

export default function FileUploadComponent(props: FileUploadProps) {
  const [invalidFile, setInvalidFile] = useState<string | null>();
  const { uuid, name, label, container = "application-evidence", value, warn, disabled = false } = props;
  const { setFieldValue, isSubmitting } = useFormikContext();
  const { formContextStore } = useContext(FormContext);
  const { accounts } = useMsal();

  const { fetchData } = useApiClient("fileHandler", { requireAuth: formContextStore.requireAuth ?? true });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<ModalRef>(null);

  const fileExtensions = ["doc", "docx", "xls", "xlsx", "csv", "ppt", "pptx", "pdf", "jpeg", "jpg", "png", "tiff", "mp4"];

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!isSubmitting) return;
    setInvalidFile(null);
  }, [isSubmitting]);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFile(e);
      e.dataTransfer.clearData();
    }
  };

  const handleChooseClick = () => {
    fileInputRef.current!.click();
  };

  // DEV NOTE FOR AZURITE CORS! (curse who put cors on local storage emulation and not default to *)
  // Download/install https://azure.microsoft.com/en-us/products/storage/storage-explorer/
  // in EXPLORER side nav -> Emulator & Attached -> Storage Accounts -> (Emulator - Default Ports) (Key)
  // right click Blob Containers -> Configure CORS settings -> Add the values below
  // Allowed Origins: *, Allowed Headers: content-type,x-ms-*, Exposed Headers: Etag
  const handleFile = async (event: any) => {
    setInvalidFile(null);
    const newFiles = event.target.files ?? event.dataTransfer.files;
    const completeFiles = [...value.files];
    const account = accounts.length > 0 ? (accounts[0].idTokenClaims?.email as string) : "";
    for (const file of [...newFiles]) {
      if (!fileExtensions.includes(file.name.split(".")?.pop()!.toLowerCase())) {
        setInvalidFile("Please upload a valid type of file");
        return;
      }
      const fileName = `${uuid}/${file.name}${new Date().getTime().toString()}`;
      if (file.size > 2.5e8) {
        setInvalidFile("File size must be less than 250mb");
        return;
      }

      try {
        const data = await fetchData("write", { filename: fileName, container: container });
        const { sas } = data.data;
        const blobServiceClient = new BlobServiceClient(`${import.meta.env.VITE_STORAGEACCOUNT}${sas}`);
        const containerClient = blobServiceClient.getContainerClient(container);
        eventEmitter.emit("loading", { isLoading: true });
        await containerClient.getBlockBlobClient(fileName).upload(file, file.size, {
          onProgress: (ev) => {
            console.log(`${ev.loadedBytes} bytes transferred.`);
          },
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
        });

        await postValidateFile(container + "/" + fileName).then((data) => {
          if (data?.data["res"] != "") {
            setInvalidFile("Invalid file upload. Please try again, if error persists contact support.");
            const toastData: ToastInfo = { title: "File Upload Error", message: data?.data["res"], severity: "error" };
            eventEmitter.emit("tstr", toastData);
            return;
          } else {
            const newFile: fileData = {
              fileName: file.name,
              size: file.size,
              contentType: file.type,
              emailCreated: account,
              lastModified: file.lastModified,
              blobKey: fileName,
              uploadDate: new Date().toISOString(),
            };
            completeFiles.push(newFile);
          }
        });
      } catch (err) {
        console.error(err);
      } finally {
        eventEmitter.emit("loading", { isLoading: false });
      }
    }
    setFieldValue(name, { files: completeFiles });
  };

  const downloadFile = (file: fileData) => {
    fetchData("read", { filename: file.blobKey, container: container }).then(({ data }) => {
      const { url } = data;
      const ext = file.contentType;
      const isProblematicExtension = ["rtf", "heic"].some((x) => ext.includes(x));
      if (isProblematicExtension) {
        fetch(url)
          .then((b) => b.blob())
          .then((blob) => {
            saveAs(blob, file.fileName);
          });
      } else {
        window.open(url, "_blank");
      }
    });
  };

  const deleteFile = () => {
    const f = [...value.files];
    f.splice(deleteFileIndx!, 1);
    setFieldValue(name, { files: f });
    modalRef.current?.toggle();
  };

  const [deleteFileIndx, setDeleteFileIndex] = useState<number>(0);

  return (
    <>
      <label>{label}</label>
      {warn && <AdvisoryText type="warning" label={warn} />}
      {!disabled && (
        <Card className="border border-1 shadow-none border-noround">
          <div onDragOver={handleDragOver} onDrop={handleDrop} className="flex flex-column align-center text-center p-2">
            Drag and drop or choose file to attach documents
            <Button type="button" label="Choose" icon="pi pi-upload" onClick={handleChooseClick} className="outline-button p-mt-3 m-auto" />
          </div>
          <input type="file" multiple ref={fileInputRef} style={{ display: "none" }} onChange={handleFile} />
        </Card>
      )}
      {invalidFile && <div className="error">{invalidFile}</div>}
      <ErrorMessage name={name + ".files"} render={(msg) => <div className="error">{msg}</div>} />
      {value.files.length >= 1 && (
        <>
          <table className=" w-full mt-5 upload-table">
            <thead>
              <th className="p-2 w-3rem"></th>
              <th className="p-2">File name and type</th>
              <th className="p-2">File Size</th>
              <th className="p-2">Email of submitting user</th>
              <th className="p-2">Date and time stamp of the file upload</th>
              <th className="p-2">Actions</th>{" "}
            </thead>
            {value.files.map((rowData, index) => (
              <FileTemplate
                key={index}
                rowData={rowData}
                index={index}
                disabled={disabled}
                downloadFile={downloadFile}
                setDeleteFileIndex={setDeleteFileIndex}
                toggle={() => modalRef.current?.toggle()}
                external={props.externalForm}
              />
            ))}
          </table>
          <Modal
            ref={modalRef}
            confirmAction={() => deleteFile()}
            headerLabel="Delete File"
            confirmLabel="Confirm"
            rejectLabel="Cancel"
            bodyText={`You are deleting '${value.files[deleteFileIndx]?.fileName}' file`}
          />
        </>
      )}
    </>
  );
}

function FileTemplate({
  rowData,
  index,
  disabled,
  downloadFile,
  setDeleteFileIndex,
  toggle,
  external,
}: {
  rowData: fileData;
  index: number;
  disabled: boolean;
  downloadFile: (rowData: fileData) => void;
  setDeleteFileIndex: (index: number) => void;
  toggle?: () => void;
  external?: boolean;
}) {
  const getFileIcon = (fileType: string): string => {
    switch (fileType) {
      case "application/pdf":
        return "pi pi-file-pdf";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": // .docx
        return "pi pi-file-word";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": // .xlsx
        return "pi pi-file-excel";
      case "image/jpeg":
      case "image/png":
      case "image/gif":
      case "image/bmp":
      case "image/webp":
        return "pi pi-image";
      default:
        return "pi pi-file";
    }
  };

  const formatBits = (bits: number): string => {
    const bytes = bits / 8;

    switch (true) {
      case bytes >= 1024 * 1024:
        return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

      case bytes >= 1024:
        return `${(bytes / 1024).toFixed(2)} KB`;

      default:
        return `${bytes.toFixed(2)} Bytes`;
    }
  };

  const [formattedData, setFormattedData] = useState<any>();

  useEffect(() => {
    const d = new Date(rowData.uploadDate);
    if (rowData.dateCreated) {
      d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
    }
    const dta = {
      icon: getFileIcon(rowData.contentType),
      size: formatBits(rowData.size),
      uploadDate: `${d.toLocaleDateString("en-CA")} ${d.toLocaleString("en-CA", { timeStyle: "medium", hour12: false })}`,
    };
    setFormattedData(dta);
  }, [rowData]);

  return (
    <tr key={index} className=" w-12 p-3 row ">
      <td className="">
        <span className="ml-2 mt-1">{index}</span>
      </td>

      <td className="column-width wrap-text">
        <i className={formattedData?.icon + " m-auto"} />
        <span className="ml-2 mt-1">{rowData.fileName}</span>
      </td>
      <td className="column-width">
        <span className="ml-2 mt-1">{formattedData?.size}</span>
      </td>

      <td className="column-width wrap-text">
        <span className="ml-2 mt-1">{rowData.emailCreated}</span>
      </td>
      <td className="column-width">
        <span className="ml-2 mt-1">{formattedData?.uploadDate}</span>
      </td>
      <td className=" flex mt-2">
        {!external && <Button label="Download" type="button" className="link mr-5" onClick={() => downloadFile(rowData)} icon="pi pi-download" />}
        {rowData.dateCreated == null && !disabled && (
          <Button
            label="Delete"
            type="button"
            className="link"
            onClick={() => {
              setDeleteFileIndex(index);
              toggle?.();
            }}
            icon="pi pi-trash"
          />
        )}
      </td>
    </tr>
  );
}

export interface fileData {
  lastModified: number;
  fileName: string;
  emailCreated?: string;
  size: number;
  contentType: string;
  blobKey: string;
  uploadDate: Date | string;
  dateCreated?: Date;
}
