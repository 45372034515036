import React, { useEffect, useState } from "react";
import { useApiClient } from "../../shared/customHooks";
import { useSearchParams } from "react-router-dom";
import { externalApplicationFilesForm } from "../../shared/formObjects/external/externalApplicationFilesForm";
import FormContainer from "../../components/form/FormContainer";
import ExternalFormsHeader from "./header/ExternalFormsHeader";
import { PageHeader } from "../../components/header/PageHeader";

function ExternalApplicationEvidence() {
  const { fetchData } = useApiClient("ExternalLink/veryify", { requireAuth: false });
  const [denied, setDenied] = useState(false);
  const [loadForm, setLoadForm] = useState(false);
  const [id, setId] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const headerText = [
    {
      class: "p-0 m-0 flex align-center",
      value: "KIS Application and Evidence Upload",
    },
  ];
  const [formContext, setFormContext] = useState<any>({ requireAuth: false });

  const externalEvidenceApi = useApiClient("ExternalEvidence/" + id, { requireAuth: false });
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const idParam = searchParams.get("id");
    setId(idParam ?? "");
    if (idParam == null) {
      setDenied(true);
    } else {
      fetchData(idParam!)
        .then((data) => {
          setFormContext({ ...formContext, uuid: data.data.fileUuid });
          setDenied(false);
          setLoadForm(true);
        })
        .catch(() => {
          setDenied(true);
        });
    }
  }, []);

  function rejectedSave(err: any) {
    if (
      err.response.data.message ===
      "The link used to access this form is no longer active. Please contact the KIS team on phone or email to be issued with an active link."
    ) {
      setDenied(true);
    }
  }
  function successSave() {
    setSubmitted(true);
  }
  return denied ? (
    <div className="flex align-items-center justify-content-center access">
      <img src="/assets/icons/bulb.svg" width="80" className="border border-2 border-circle p-3 border-primary bg-white" />
      <h3 className="bg-grey p-5 adv-text-access">
        {" "}
        The link used to access this form is no longer active. Please contact the KIS team on 1300 564 149 or support@kisapplications.com.au to be
        issued with an active link.
      </h3>
    </div>
  ) : (
    <>
      {submitted ? (
        <>
          <div className="flex align-items-center justify-content-center access">
            <img src="/assets/icons/bulb.svg" width="80" className="border border-2 border-circle p-3 border-primary bg-white" />
            <h3 className="bg-grey p-5 adv-text-access">
              {" "}
              Thank you for submitting additional KIS documentation. You will receive an email confirming receipt of this information shortly.
            </h3>
          </div>
        </>
      ) : (
        <>
          <ExternalFormsHeader></ExternalFormsHeader>
          <PageHeader headerText={headerText} hideBreadcrumbs={true} icon={"icon"}></PageHeader>

          {loadForm && (
            <FormContainer
              apiClient={externalEvidenceApi}
              formBody={externalApplicationFilesForm}
              formTitle=""
              formContext={formContext}
              saveErrorCB={rejectedSave}
              saveSuccessCB={successSave}
              showInternalNotes={false}
            />
          )}
        </>
      )}
    </>
  );
}

export default ExternalApplicationEvidence;
