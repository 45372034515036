import React, { useEffect, useState } from "react";
import ApplicationFiles from "../../components/applicationComponents/applicationFiles/ApplicationFilesComponent";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { PageHeader } from "../../components/header/PageHeader";

export default function ApplicationFilesPage() {
  const [id, setId] = useState("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const idParam = searchParams.get("id");
    setId(idParam ?? "");
  }, []);
  const headerText = [
    {
      class: "flex align-center mt-0",
      value: "Edit Application and Evidence Upload",
      icon: "view-app-icon mr-3 mt-0",
    },
  ];
  return (
    <>
      <Header />
      <PageHeader headerText={headerText} icon={"icon"} />
      <ApplicationFiles disabled={false} applicationId={id} />
    </>
  );
}
