import { FormGroup } from "../../../components/form";
import * as Yup from "yup";

export const externalApplicationFilesForm: FormGroup = {
  type: "formGroup",
  test: [
    "atleastOne",
    function (value) {
      // Check if at least one of the `files` arrays has a file
      const { applicationFormPartOne, applicationFormPartTwo, evidenceDocuments } = value;
      const result =
        (applicationFormPartOne?.files?.length ?? 0) > 0 ||
        (applicationFormPartTwo?.files?.length ?? 0) > 0 ||
        (evidenceDocuments?.files?.length ?? 0) > 0;
      return result;
    },
  ],
  fields: [
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "warning",
      },
      label:
        "Upload any updated application forms and all additional information in the sections as indicated. Please do not re-upload application forms and documents that have not changed.",
    },
    {
      type: "field",
      key: "applicationFormPartOne",
      fieldType: "file",
      validation: Yup.object({
        files: Yup.array(),
      }),
      label: "Application Form - Section 1",
      warn: "This section is for the KIS program application form - Section 1. Please do not re-upload the application form if it has not changed.",
      //dynamic set values
      expressionProps: {
        uuid: (context) => context.uuid,
      },
      //static set values
      props: {
        externalForm: true,
      },
    },
    {
      type: "field",
      key: "applicationFormPartTwo",
      fieldType: "file",
      validation: Yup.object({
        files: Yup.array(),
      }),
      label: "Application Form - Section 2",
      warn: "This section is for the KIS program application form - Section 2. Please do not re-upload the application form if it has not changed.",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
      props: {
        externalForm: true,
      },
    },
    {
      type: "field",
      key: "evidenceDocuments",
      fieldType: "file",
      validation: Yup.object({
        files: Yup.array(),
      }),
      label: "Evidence Documents",
      warn: "This section is for all of the Evidence Documents being used to support the application. Please do not re-upload previously supplied Evidence Documents that have not changed.",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
      props: {
        externalForm: true,
      },
    },
    {
      type: "field",
      key: "atleastOne",
      fieldType: "error",
      validation: Yup.boolean().oneOf([true], "Application cannot be submitted unless at least one document has been uploaded"),
    },
  ],
};
